import React from 'react';
import { Helmet } from 'react-helmet';

import { ChordDiagram } from '../components/charts';
import { Paper, PaperPadding, PaperTitle } from '../components/papers';
import { AppLayout } from '../layout';

/**
 *
 */
export function Queries (props) {
  const { appData, charts } = props.pageContext;

  return (
    <AppLayout data={appData} title="Most In-Demand Tech Roles">
      <Helmet>
        <title>Roles | StackTrends</title>
        <meta name="description" content="" />
      </Helmet>

      <Paper>
        <PaperTitle>Tech Jobs by Role</PaperTitle>
        <PaperPadding>
          <ChordDiagram chartId="chord_diagram_query_shared_listings" data={charts.chordSharedListings} />
        </PaperPadding>
    </Paper>
    </AppLayout>
  );
}

export default Queries;
